/*  */

.preview-img-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.preview-img-wrap img {
  width: 100%;
  height: 100%;
}

.highcharts-root {
  width: 100%;
  height: 100%;
}

.notifications-component {
  z-index: 999999 !important;
}
.multi-range-slider {
  box-shadow: none;
  border: none;
}

.table > :not(:first-child) {
  border-top: none;
}

/* .container {
  max-width: 100%;
} */

.privacy-policy {
  margin-top: 20px;
  padding-bottom: 20px;
}

.terms-of-use {
  margin-top: 20px;
  padding-bottom: 20px;
}

.react-flow__node.react-flow__node-default,
.react-flow__node-group,
.react-flow__node-input,
.react-flow__node-output {
  width: 235px !important;
}

/* .fade {
  opacity: 1 !important;
} */

.edgebutton {
  background-color: whitesmoke !important;
  border: none !important;
  padding: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

._oBfRr {
  transition: color 0.15s, background-color 0.15s;
  position: absolute;
  min-width: 160px;
  list-style: none;
  background-clip: padding-box;
  border-radius: 4px;
  box-shadow: 0 1px 15px rgb(27 31 35 / 15%);
  color: #333;
  border: 1px solid rgba(27, 31, 35, 0.15);
  max-width: 300px;
  padding: 10px;
}

._oBfRr,
.edgebutton {
  background-color: #fff;
}

._oBfRr,
._oBfRr h3 {
  margin: 0;
}

.offcanvas-header .btn-close::before {
  top: 5px !important;
}
[variant="error"],
.toast-error {
  color: #fff !important;
  background-color: #eb554b !important;
  border-color: #eb554b !important;
}

.accolades .popover-body {
  height: 350px;
  /* overflow: auto; */
}