/* Custom global scrollbar */

::-webkit-scrollbar {
	width: 1.5em;
	background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
	background-color: #dadada;
	border-radius: 100vw;
	margin-block: 0.5em;
}

::-webkit-scrollbar-thumb {
	background-color: hsl(238 26% 47% / 1);
	border: 0.35em solid #dadada;
	border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
	background-color: hsl(238 26% 37% / 1);
}

/* Custom scrollbar accoaldes */

#style-3::-webkit-scrollbar {
	width: 6px;
	background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
	background-color: hsl(238 26% 47% / 1);
	border: 1px solid #f5f5f5;
}

.scrollbar {
	float: left;
	height: 100%;
	width: 100%;
	background: #f5f5f5;
	overflow-y: scroll;
}
