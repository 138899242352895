* a {
	text-decoration: none !important;
}

body {
	-webkit-font-smoothing: antialiased !important;
	font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial",
		sans-serif !important;
	text-rendering: optimizeLegibility !important;
	background-repeat: no-repeat !important;
	background-attachment: fixed !important;
	background-size: cover !important;
	text-rendering: optimizeLegibility !important;
	font-size: 14px !important;
	height: 100% !important;
}

/* @media only screen and (min-width: 1440px) {
	:root {
		font-size: 20px;
	}
} */

ul,
ol {
	list-style: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial",
		sans-serif !important;
	font-weight: 700 !important;
	letter-spacing: 0 !important;
	color: #212529 !important;
}

h1 {
	font-size: 24px;
}

h2 {
	font-size: 18px;
}

h3 {
	font-size: 16px;
}

h4 {
	font-size: 14px;
}

h5 {
	font-size: 12px;
}

h6 {
	font-size: 10px;
}

input::placeholder {
	font-family: "Lato", sans-serif !important;
}

.App {
	position: relative !important;
}

/* React notifications css override */
.rnc__base {
	z-index: 999999 !important;
}

.rnc__notification-container--top-right {
	top: 60px !important;
}
